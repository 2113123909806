/* Footer */

.footer-main {
    background-color: #fff;
    --margin: 15px;
    font-size: var(--font-s);
}
.footer-main .container,
.footer-main .container-narrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
}
.footer-main ul,
.footer-main nav {
    display: flex;
    justify-content: flex-end;
    margin: 10px calc(-1 * var(--margin));
}
.footer-main ul li,
.footer-main nav a {
    display: block;
    margin: 0 var(--margin);
}
.footer-up{
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 99;
    background-color: var(--light);
    text-decoration: underline;
    position: fixed;
    bottom: var(--min-gutter);
    right: var(--min-gutter);
    height: 40px;
    width: 40px;
    padding: 12px;
    transition: var(--transalte);
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-up svg{
    fill: none;
}
.fix .footer-up{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: var(--transalte);
}
.footer-top {
    display: flex;
    align-items: center;
}
.footer-top picture{
    width: 100%;
    max-height: 40vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.footer-top picture img{
    object-fit: cover;
}
.footer-top .img-contact{
    position: absolute;
    z-index: 1;
    background-color: var(--light);
    right: 0;
    height: 16vh;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6vw;
    line-height: 1.4;
}
.footer-middle{
    padding: var(--mid-padding) 0;
}
.footer-middle .left,
.footer-middle .right {
    padding: 10px;
    max-width: 20em;
    display: flex;
    flex-direction: column;
}
.footer-middle .left > * + *,
.footer-middle .right > * + * {
    margin-top: var(--gutter);
}
.footer-middle .left div > * {
    margin-bottom: var(--min-gutter);
}


@media screen and (max-width: 1300px) {

    .footer-bottom{
        flex-direction: column;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 700px) {
    .footer-middle.container-narrow{
        flex-direction: column;
    }
    .footer-bottom{
        text-align: center;
    }
    .footer-main ul,
    .footer-main nav{
        flex-direction: column;
        align-items: center;
    }
    .footer-top .img-contact{
        width: 60%;
    }
}